import { MenuItem } from './menu.model';

export function getFilteredMenuItems(userRoleId: number): MenuItem[] {
    // Deep copy the MENU array to avoid mutating the original data
    const menuCopy = JSON.parse(JSON.stringify(MENU));

    return menuCopy.filter((menuItem) => {
        if (menuItem.roles?.includes(userRoleId)) {
            // If the menu item has sub-items, filter them as well
            if (menuItem.subItems) {
                menuItem.subItems = menuItem.subItems.filter((subItem: MenuItem) => subItem.roles?.includes(userRoleId));
            }
            return true;
        }
        return false;
    });
}

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        roles: [1, 2],
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        badge: {
            variant: 'primary',
            text: '01', //'MENUITEMS.DASHBOARDS.BADGE',
        },
        link: '/',
        roles: [1, 2],
    },
    {
        id: 20,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        badge: {
            variant: 'primary',
            text: '01', //'MENUITEMS.DASHBOARDS.BADGE',
        },
        link: '/agentdashboards/agentdashboard',
        roles: [3],
    },
    {
        id: 21,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        badge: {
            variant: 'primary',
            text: '01', //'MENUITEMS.DASHBOARDS.BADGE',
        },
        link: '/coordinatordashboards/coordinatordashboard',
        roles: [4],
    },
    // {
    //     id: 3,
    //     isLayout: true
    // },
    {
        id: 4,
        label: 'MENUITEMS.APPS.TEXT',
        isTitle: true,
        roles: [1, 2],
    },
    {
        id: 5,
        label: 'Project',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-list-ul',
        link: '/project/list',
        roles: [1, 2, 3, 4],
    },
    {
        id: 6,
        label: 'Client',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-users-alt',
        link: '/client/list',
        roles: [1, 2],
    },
    {
        id: 7,
        label: 'Invite User',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-user-plus',
        link: '/user/list',
        roles: [1, 2],
    },
    {
        id: 8,
        label: 'Bank',//MENUITEMS.PROJECT.TEXT
        icon: 'bx bxs-bank',
        link: '/company-accounts/list',
        roles: [1, 2],
    },
    {
        id: 9,
        label: 'Customer',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-users-alt',
        link: '/customer/list',
        roles: [1, 2],
    },
    {
        id: 15,
        label: 'Deposit',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-money-withdraw',
        link: '/deposit/list',
        roles: [1, 2, 3],
    },
    {
        id: 16,
        label: 'Transfer',//MENUITEMS.PROJECT.TEXT
        icon: 'bx bx-transfer-alt',
        link: '/transfer/list',
        roles: [1, 2],
    },
    {
        id: 17,
        label: 'Withdrawal',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-money-withdrawal',
        link: '/withdrawal/list',
        roles: [1, 2, 3],
    },
    {
        id: 17,
        label: 'Claim Invoice',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-invoice',
        link: '/claimInvoice/list',
        roles: [1, 2],
    },
    {
        id: 10,
        label: 'Admin',
        icon: 'uil-store',
        roles: [1, 2],
        subItems: [
            {
                id: 11,
                label: 'Channel Partner',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/admin/channel-partner',
                roles: [1, 2],
            },
            {
                id: 12,
                label: 'Branch',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/admin/branch',
                roles: [1, 2],
            },
            {
                id: 110,
                label: 'Coordinator',//Email test
                icon: 'uil-comments-alt',
                link: '/admin/coordinator',///admin/eamiltest
                roles: [1, 2],
            },
            {
                id: 110,
                label: 'eamil test',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/admin/eamiltest',
                roles: [1, 2],
            },
            // {
            //     id: 111,
            //     label: 'Country',//MENUITEMS.PROJECT.TEXT
            //     icon: 'uil-comments-alt',
            //     link: '/admin/country',
            // },
            {
                id: 13,
                label: 'CP master',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/admin/cp',
                roles: [1, 2],
            },
            // {
            //     id: 113,
            //     label: 'Currency Master',//MENUITEMS.PROJECT.TEXT
            //     icon: 'uil-comments-alt',
            //     link: '/admin/currency-master',
            // },
            {
                id: 14,
                label: 'Methods',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/admin/methods',
                roles: [1, 2],
            },

            // {
            //     id: 115,
            //     label: 'Nationality Master',//MENUITEMS.PROJECT.TEXT
            //     icon: 'uil-comments-alt',
            //     link: '/admin/nationality-master',
            // },
            // {
            //     id: 116,
            //     label: 'Province',//MENUITEMS.PROJECT.TEXT
            //     icon: 'uil-comments-alt',
            //     link: '/admin/province',
            // }
        ]
    },
    {
        id: 17,
        label: 'Reports',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-invoice',
        link: '/report/userstatus',
        roles: [1, 2,3,4],
    },

];

