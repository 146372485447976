import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthenticationService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private primengConfig: PrimeNGConfig, private authService: AuthenticationService) {}
  ngOnInit() {
    this.authService.hideLayout.next(false);
    //  document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");  
  }

}
