import { ConfigService } from "src/app/core/services/config.service";
import { environment } from "./environments/environment";

//project
ConfigService.set('projectList', environment.aidflowapi.projectList);
ConfigService.set('projectListCount', environment.aidflowapi.projectListCount);
ConfigService.set('projectDelete', environment.aidflowapi.projectDelete);
ConfigService.set('projectUpsert', environment.aidflowapi.projectUpsert);
ConfigService.set('getProjectDetails', environment.aidflowapi.getProjectDetails);
ConfigService.set('csvUpload', environment.aidflowapi.csvUpload);
ConfigService.set('folderImgUpload', environment.aidflowapi.folderImgUpload);
ConfigService.set('getBatchDetails', environment.aidflowapi.getBatchDetails);
ConfigService.set('getImportDetails', environment.aidflowapi.getImportDetails);
ConfigService.set('batchDelete', environment.aidflowapi.batchDelete);
ConfigService.set('projectBatchList', environment.aidflowapi.projectBatchList);
ConfigService.set('projectBatchListCount', environment.aidflowapi.projectBatchListCount);
ConfigService.set('importIssuesList', environment.aidflowapi.importIssuesList);
ConfigService.set('importIssuesListCount', environment.aidflowapi.importIssuesListCount);
ConfigService.set('importDelete', environment.aidflowapi.importDelete);

ConfigService.set('importIgnoreById', environment.aidflowapi.importIgnoreById);
ConfigService.set('importIgnoreRecodsByType', environment.aidflowapi.importIgnoreRecodsByType);
ConfigService.set('importBulkProcessUpsert', environment.aidflowapi.importBulkProcessUpsert);

ConfigService.set('distributionPlanList', environment.aidflowapi.distributionPlanList);
ConfigService.set('distributionPlanListCount', environment.aidflowapi.distributionPlanListCount);

ConfigService.set('projectStatusUpsert', environment.aidflowapi.projectStatusUpsert);
ConfigService.set('projectStatusUpsert_TeamEmail', environment.aidflowapi.projectStatusUpsert_TeamEmail);
ConfigService.set('AssignUserDistributionList', environment.aidflowapi.AssignUserDistributionList);

ConfigService.set('import_new_customers', environment.aidflowapi.import_new_customers);

ConfigService.set('sendEmail', environment.aidflowapi.sendEmail);
ConfigService.set('logStatusUpsert', environment.aidflowapi.logStatusUpsert);
ConfigService.set('verifyToken', environment.aidflowapi.verifyToken);

ConfigService.set('DonorRequirementsChanges', environment.aidflowapi.DonorRequirementsChanges);

ConfigService.set('triggerImportPendingBathces', environment.aidflowapi.triggerImportPendingBathces);
ConfigService.set('triggerImportdistribution_plan', environment.aidflowapi.triggerImportdistribution_plan);
ConfigService.set('triggerTransactionUpsert', environment.aidflowapi.triggerTransactionUpsert);



//channel partner
ConfigService.set('channelPartnerList', environment.aidflowapi.channelPartnerList);
ConfigService.set('channelPartnerListCount', environment.aidflowapi.channelPartnerListCount);
ConfigService.set('channelPartnerDelete', environment.aidflowapi.channelPartnerDelete);
ConfigService.set('channelPartnerUpsert', environment.aidflowapi.channelPartnerUpsert);
ConfigService.set('getChannelPartnerDetails', environment.aidflowapi.getChannelPartnerDetails);

//branch
ConfigService.set('branchList', environment.aidflowapi.branchList);
ConfigService.set('branchListCount', environment.aidflowapi.branchListCount);
ConfigService.set('branchDelete', environment.aidflowapi.branchDelete);
ConfigService.set('branchUpsert', environment.aidflowapi.branchUpsert);
ConfigService.set('getBranchDetails', environment.aidflowapi.getBranchDetails);

//city
ConfigService.set('cityList', environment.aidflowapi.cityList);
ConfigService.set('cityListCount', environment.aidflowapi.cityListCount);
ConfigService.set('cityDelete', environment.aidflowapi.cityDelete);
ConfigService.set('cityUpsert', environment.aidflowapi.cityUpsert);

//country
ConfigService.set('countryList', environment.aidflowapi.countryList);
ConfigService.set('countryListCount', environment.aidflowapi.countryListCount);
ConfigService.set('countryDelete', environment.aidflowapi.countryDelete);
ConfigService.set('countryUpsert', environment.aidflowapi.countryUpsert);

//cp master
ConfigService.set('cpMasterList', environment.aidflowapi.cpMasterList);
ConfigService.set('cpMasterListCount', environment.aidflowapi.cpMasterListCount);
ConfigService.set('cpMasterDelete', environment.aidflowapi.cpMasterDelete);
ConfigService.set('cpMasterUpsert', environment.aidflowapi.cpMasterUpsert);;
ConfigService.set('getCPMasterDetails', environment.aidflowapi.getCPMasterDetails);

//currency master
ConfigService.set('currencyMasterList', environment.aidflowapi.currencyMasterList);
ConfigService.set('currencyMasterListCount', environment.aidflowapi.currencyMasterListCount);
ConfigService.set('currencyMasterDelete', environment.aidflowapi.currencyMasterDelete);
ConfigService.set('currencyMasterUpsert', environment.aidflowapi.currencyMasterUpsert);

//methods
ConfigService.set('methodsList', environment.aidflowapi.methodsList);
ConfigService.set('methodsListCount', environment.aidflowapi.methodsListCount);
ConfigService.set('methodsDelete', environment.aidflowapi.methodsDelete);
ConfigService.set('methodsUpsert', environment.aidflowapi.methodsUpsert);
ConfigService.set('getMethodsDetails', environment.aidflowapi.getMethodsDetails);
ConfigService.set('getMethodsByType', environment.aidflowapi.getMethodsByType);



//nationality master
ConfigService.set('nationalityMasterList', environment.aidflowapi.nationalityMasterList);
ConfigService.set('nationalityMasterListCount', environment.aidflowapi.nationalityMasterListCount);
ConfigService.set('nationalityMasterDelete', environment.aidflowapi.nationalityMasterDelete);
ConfigService.set('nationalityMasterUpsert', environment.aidflowapi.nationalityMasterUpsert);

//province
ConfigService.set('provinceList', environment.aidflowapi.provinceList);
ConfigService.set('provinceListCount', environment.aidflowapi.provinceListCount);
ConfigService.set('provinceDelete', environment.aidflowapi.provinceDelete);
ConfigService.set('provinceUpsert', environment.aidflowapi.provinceUpsert);

//user
ConfigService.set('userList', environment.aidflowapi.userList);
ConfigService.set('userListCount', environment.aidflowapi.userListCount);
ConfigService.set('userDelete', environment.aidflowapi.userDelete);
ConfigService.set('userUpsert', environment.aidflowapi.userUpsert);
ConfigService.set('getUserDetails', environment.aidflowapi.getUserDetails);


//client
ConfigService.set('clientList', environment.aidflowapi.clientList);
ConfigService.set('clientListCount', environment.aidflowapi.clientListCount);
ConfigService.set('clientDelete', environment.aidflowapi.clientDelete);
ConfigService.set('clientUpsert', environment.aidflowapi.clientUpsert);
ConfigService.set('getClientDetails', environment.aidflowapi.getClientDetails);


//client
ConfigService.set('companyAccountsList', environment.aidflowapi.companyAccountsList);
ConfigService.set('companyAccountsListCount', environment.aidflowapi.companyAccountsListCount);
ConfigService.set('companyAccountsDelete', environment.aidflowapi.companyAccountsDelete);
ConfigService.set('companyAccountsUpsert', environment.aidflowapi.companyAccountsUpsert);
ConfigService.set('getCompanyAccountDetails', environment.aidflowapi.getCompanyAccountDetails);

//customer
ConfigService.set('customerList', environment.aidflowapi.customerList);
ConfigService.set('customerListCount', environment.aidflowapi.customerListCount);
ConfigService.set('customerDelete', environment.aidflowapi.customerDelete);
ConfigService.set('customerUpsert', environment.aidflowapi.customerUpsert);
ConfigService.set('getCustomerDetails', environment.aidflowapi.getCustomerDetails);
// ConfigService.set('customerRelationShipUpsert1', environment.aidflowapi.customerRelationShipUpsert);
ConfigService.set('customerAddressUpsert', environment.aidflowapi.customerAddressUpsert);
ConfigService.set('getCustomerAddresses', environment.aidflowapi.getCustomerAddresses);
ConfigService.set('customerRelationshipUpsert', environment.aidflowapi.customerRelationshipUpsert);
ConfigService.set('getCustomerRelationship', environment.aidflowapi.getRelationshipAddresses);
ConfigService.set('searchCustomerDetails', environment.aidflowapi.searchCustomerDetails);
ConfigService.set('getCustomerAccountPin', environment.aidflowapi.getCustomerAccountPin);
ConfigService.set('customerAccountPinUpsert', environment.aidflowapi.customerAccountPinUpsert);
ConfigService.set('customerGenerateOTP', environment.aidflowapi.customerGenerateOTP);
ConfigService.set('customerVerifyOTP', environment.aidflowapi.customerVerifyOTP);
ConfigService.set('importCustomer', environment.aidflowapi.importCustomer);


ConfigService.set('customerUploadFile', environment.aidflowapi.customerUploadFile);
ConfigService.set('customerGetFile', environment.aidflowapi.customerGetFile);
ConfigService.set('customerByCif', environment.aidflowapi.customerByCif);

ConfigService.set('getCustomerSetting', environment.aidflowapi.getCustomerSetting);
ConfigService.set('customerSettingUpsert', environment.aidflowapi.customerSettingUpsert);


//Deposit
ConfigService.set('depositList', environment.aidflowapi.getDepositList);
ConfigService.set('depositListCount', environment.aidflowapi.getDepositListCount);
ConfigService.set('depositUpsert', environment.aidflowapi.depositUpsert);
ConfigService.set('getDepositDetails', environment.aidflowapi.getDepositDetails);

//Transfer
ConfigService.set('transferList', environment.aidflowapi.getTransferList);
ConfigService.set('transferListCount', environment.aidflowapi.getTransferListCount);
ConfigService.set('transferUpsert', environment.aidflowapi.TransferUpsert);
ConfigService.set('gettransferDetails', environment.aidflowapi.getTransferDetails);

//Withdrawal
ConfigService.set('withdrawalList', environment.aidflowapi.getWithdrawalList);
ConfigService.set('withdrawalListCount', environment.aidflowapi.getWithdrawalListCount);
ConfigService.set('withdrawalUpsert', environment.aidflowapi.withdrawalUpsert);
ConfigService.set('getWithdrawalDetails', environment.aidflowapi.getWithdrawalDetails);
ConfigService.set('getCustomerAccountDetails', environment.aidflowapi.getCustomerAccountDetails);
ConfigService.set('getCustomerAccountBalance', environment.aidflowapi.getCustomerAccountBalance);
ConfigService.set('AmountBasedOnProject', environment.aidflowapi.AmountBasedOnProject);
ConfigService.set('transactionUpsert', environment.aidflowapi.transactionUpsert);

//lookup
ConfigService.set('getlookups', environment.aidflowapi.getlookups);


//coordinator
ConfigService.set('coordinatorList', environment.aidflowapi.coordinatorList);
ConfigService.set('coordinatorListCount', environment.aidflowapi.coordinatorListCount);
ConfigService.set('PatnersCoordinatorDelete', environment.aidflowapi.PatnersCoordinatorDelete);
ConfigService.set('coordinatorUpsert', environment.aidflowapi.coordinatorUpsert);
ConfigService.set('getPatnersCoordinatorDetails', environment.aidflowapi.getPatnersCoordinatorDetails);

//Dashboard
ConfigService.set('runningProjects', environment.aidflowapi.runningProjects);
ConfigService.set('dashboardMetrics', environment.aidflowapi.dashboardMetrics);

//Auth
ConfigService.set('getAuthenticate', environment.aidflowapi.getAuthenticate);



//ClaimInvoic
ConfigService.set('claimInvoiceList', environment.aidflowapi.claimInvoiceList);
ConfigService.set('claimInvoiceListCount', environment.aidflowapi.claimInvoiceListCount);
ConfigService.set('claimInvoiceDelete', environment.aidflowapi.claimInvoiceDelete);
ConfigService.set('claimInvoiceUpsert', environment.aidflowapi.claimInvoiceUpsert);
ConfigService.set('claimInvoiceSimulate', environment.aidflowapi.claimInvoiceSimulate);
ConfigService.set('claimInvoiceProjectList', environment.aidflowapi.claimInvoiceProjectList);
//CliamInvoice/projects