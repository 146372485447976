export const AIDFLOW_API_URL = 'https://aidflow.site:3002/api';
//export const AIDFLOW_API_URL = 'http://localhost:3001/api';
export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  aidflowapi: {
    //project
    projectList: getCompletePath('/project/list'),
    projectListCount: getCompletePath('/project/count'),
    projectDelete: getCompletePath('/project/delete/{id}'),
    projectUpsert: getCompletePath('/project'),
    getProjectDetails: getCompletePath('/project/getProjectDetails?project_id={id}'),
    csvUpload: getCompletePath('/project/csvUpload'),
    folderImgUpload: getCompletePath('/project/folderImgUpload'),
    getBatchDetails: getCompletePath('/project/getBatchDetails?project_id={id}'),
    getImportDetails: getCompletePath('/project/getImportDetails'),
    batchDelete: getCompletePath('/project/batch_delete/{id}'),
    projectBatchList: getCompletePath('/project/batch_list'),
    projectBatchListCount: getCompletePath('/project/batch_list_count'),
    importIssuesList: getCompletePath('/project/importIssuesList'),
    importIssuesListCount: getCompletePath('/project/importIssuesListCount'),
    importIgnoreById: getCompletePath('/project/importIgnoreById'),
    importIgnoreRecodsByType: getCompletePath('/project/importIgnoreRecodsByType'),
    importBulkProcessUpsert: getCompletePath('/project/importBulkProcessUpsert'),
    importDelete: getCompletePath('/project/import_delete/{type}'),
    distributionPlanList: getCompletePath('/project/distributionPlanList'),
    distributionPlanListCount: getCompletePath('/project/distributionPlanListCount'),

    projectStatusUpsert: getCompletePath('/project/status_log_upsert'),
    projectStatusUpsert_TeamEmail: getCompletePath('/project/upsert_logstatus_dist_team_email'),
    AssignUserDistributionList: getCompletePath('/project/assign_user_distribution_list'),
    triggerImportPendingBathces: getCompletePath('/project/import_pending_batches'),
    triggerImportdistribution_plan: getCompletePath('/project/import_distribution_plan'),
    triggerTransactionUpsert: getCompletePath('/project/import_transaction'),


    import_new_customers: getCompletePath('/project/import_new_customers'),

    sendEmail: getCompletePath('/email/send-email'),
    logStatusUpsert: getCompletePath('/email/status_log_upsert'),
    verifyToken: getCompletePath('/email/verify-token'),

    DonorRequirementsChanges: getCompletePath('/project/donor_requirements_changes'),

    //channel partner
    channelPartnerList: getCompletePath('/channel_partner/list'),
    channelPartnerListCount: getCompletePath('/channel_partner/count'),
    channelPartnerDelete: getCompletePath('/channel_partner/delete/{id}'),
    channelPartnerUpsert: getCompletePath('/channel_partner'),
    getChannelPartnerDetails: getCompletePath('/channel_partner/getChannelPartnerDetails?channel_partner_id={id}'),

    //branch
    branchList: getCompletePath('/branch/list'),
    branchListCount: getCompletePath('/branch/count'),
    branchDelete: getCompletePath('/branch/delete/{id}'),
    branchUpsert: getCompletePath('/branch'),
    getBranchDetails: getCompletePath('/branch/getBranchDetails?branch_id={id}'),

    //city
    cityList: getCompletePath('/city/list'),
    cityListCount: getCompletePath('/city/count'),
    cityDelete: getCompletePath('/city/delete/{id}'),
    cityUpsert: getCompletePath('/city'),

    //country
    countryList: getCompletePath('/country/list'),
    countryListCount: getCompletePath('/country/count'),
    countryDelete: getCompletePath('/country/delete/{id}'),
    countryUpsert: getCompletePath('/country'),

    //cp master
    cpMasterList: getCompletePath('/cp_master/list'),
    cpMasterListCount: getCompletePath('/cp_master/count'),
    cpMasterDelete: getCompletePath('/cp_master/delete/{id}'),
    cpMasterUpsert: getCompletePath('/cp_master'),
    getCPMasterDetails: getCompletePath('/cp_master/getCPMasterDetails?cp_master_id={id}'),

    //currency master
    currencyMasterList: getCompletePath('/currency_master/list'),
    currencyMasterListCount: getCompletePath('/currency_master/count'),
    currencyMasterDelete: getCompletePath('/currency_master/delete/{id}'),
    currencyMasterUpsert: getCompletePath('/currency_master'),

    //methods
    methodsList: getCompletePath('/methods/list'),
    methodsListCount: getCompletePath('/methods/count'),
    methodsDelete: getCompletePath('/methods/delete/{id}'),
    methodsUpsert: getCompletePath('/methods'),
    getMethodsDetails: getCompletePath('/methods/getMethodsDetails?methods_id={id}'),
    getMethodsByType: getCompletePath('/methods/getMethodsByType?{params}'),

    //nationality master
    nationalityMasterList: getCompletePath('/nationality_master/list'),
    nationalityMasterListCount: getCompletePath('/nationality_master/count'),
    nationalityMasterDelete: getCompletePath('/nationality_master/delete/{id}'),
    nationalityMasterUpsert: getCompletePath('/nationality_master'),


    //province
    provinceList: getCompletePath('/province/list'),
    provinceListCount: getCompletePath('/province/count'),
    provinceDelete: getCompletePath('/province/delete/{id}'),
    provinceUpsert: getCompletePath('/province'),


    //user
    userList: getCompletePath('/user/list'),
    userListCount: getCompletePath('/user/count'),
    userDelete: getCompletePath('/user/delete/{id}'),
    userUpsert: getCompletePath('/user'),
    getUserDetails: getCompletePath('/user/getUserDetails?user_id={id}'),

    //client
    clientList: getCompletePath('/client/list'),
    clientListCount: getCompletePath('/client/count'),
    clientDelete: getCompletePath('/client/delete/{id}'),
    clientUpsert: getCompletePath('/client'),
    getClientDetails: getCompletePath('/client/getClientDetails?client_id={id}'),


    //company accounts
    companyAccountsList: getCompletePath('/company_accounts/list'),
    companyAccountsListCount: getCompletePath('/company_accounts/count'),
    companyAccountsDelete: getCompletePath('/company_accounts/delete/{id}'),
    companyAccountsUpsert: getCompletePath('/company_accounts'),
    getCompanyAccountDetails: getCompletePath('/company_accounts/getCompanyAccountDetails?company_account_id={id}'),

    //customer
    customerList: getCompletePath('/customer/list'),
    customerListCount: getCompletePath('/customer/count'),
    customerDelete: getCompletePath('/customer/delete/{id}'),
    customerUpsert: getCompletePath('/customer'),
    getCustomerDetails: getCompletePath('/customer/getCustomerDetails?customer_id={id}'),
    // customerRelationShipUpsert1: getCompletePath('/customer/customerRelationShipUpsert'),
    customerAddressUpsert: getCompletePath('/customer/addresses'),
    getCustomerAddresses: getCompletePath('/customer/addresses?customer_id={id}'),
    customerRelationshipUpsert: getCompletePath('/customer/relationships'),
    getRelationshipAddresses: getCompletePath('/customer/relationships?customer_id={id}'),
    searchCustomerDetails: getCompletePath('/customer/search?search_param={param}'),
    getCustomerAccountPin: getCompletePath('/customer/account_pin?customer_id={id}'),
    customerAccountPinUpsert: getCompletePath('/customer/account_pin'),

    customerGenerateOTP: getCompletePath('/customer/generate-OPT'),
    customerVerifyOTP: getCompletePath('/customer/verify-otp'),
    customerUploadFile: getCompletePath('/customer/upload'),
    customerGetFile: getCompletePath('/customer/getFile?customer_id={id}'),

    getCustomerSetting: getCompletePath('/customer/customer-email-setting?customer_id={id}'),
    customerSettingUpsert: getCompletePath('/customer/customer-email-setting'),
    importCustomer: getCompletePath('/customer/import-customers'),
    customerByCif: getCompletePath('/customer/by-cif'),


    //Deposit
    getDepositList: getCompletePath('/deposit/list'),
    getDepositListCount: getCompletePath('/deposit/count'),
    depositUpsert: getCompletePath('/deposit'),
    getDepositDetails: getCompletePath('/deposit/getDepositDetails?transaction_id={id}'),

    //Transfer
    getTransferList: getCompletePath('/transfer/list'),
    getTransferListCount: getCompletePath('/transfer/count'),
    TransferUpsert: getCompletePath('/transfer'),
    getTransferDetails: getCompletePath('/transfer/gettransferDetails?account_transfer_id={id}'),

    //Withdrawal
    getWithdrawalList: getCompletePath('/withdrawal/list'),
    getWithdrawalListCount: getCompletePath('/withdrawal/count'),
    withdrawalUpsert: getCompletePath('/withdrawal'),
    getWithdrawalDetails: getCompletePath('/withdrawal/getWithdrawalDetails?transaction_id={id}'),
    getCustomerAccountDetails: getCompletePath('/withdrawal/getCustomerAccountDetails?account_id={id}'),
    getCustomerAccountBalance: getCompletePath('/withdrawal/customerAccountBalance?account_id={id}'),
    AmountBasedOnProject: getCompletePath('/withdrawal/customerAccountBalance_project?account_id={id}'),
    transactionUpsert: getCompletePath('/withdrawal/transactions'),

    //shared

    getlookups: getCompletePath('/shared/getlookups?{params}'),

    //coordinator
    coordinatorList: getCompletePath('/coordinator/list'),
    coordinatorListCount: getCompletePath('/coordinator/count'),
    PatnersCoordinatorDelete: getCompletePath('/coordinator/delete/{id}'),
    coordinatorUpsert: getCompletePath('/coordinator'),
    getPatnersCoordinatorDetails: getCompletePath('/coordinator/getPatnersCoordinatorDetails?patners_coordinator_id={id}'),

    //Dashboard
    runningProjects: getCompletePath('/dashboard/running_Projects_Dashboard'),
    dashboardMetrics: getCompletePath('/dashboard/Dashboard_Metrics'),

    //Auth
    getAuthenticate: getCompletePath('/auth/login'),


    //ClaimInvoic
    claimInvoiceList: getCompletePath('/claimInvoice/list'),
    claimInvoiceListCount: getCompletePath('/claimInvoice/count'),
    claimInvoiceDelete: getCompletePath('/claimInvoice/delete/{id}'),
    claimInvoiceUpsert: getCompletePath('/claimInvoice'),
    claimInvoiceProjectList: getCompletePath('/claimInvoice/projects'),
    claimInvoiceSimulate: getCompletePath('/claimInvoice/simulate-invoice'),
  }



};

function getCompletePath(apiPath: string) {
  return (AIDFLOW_API_URL + apiPath);
}