import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
    ) { }

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     let token = this.authService.getToken();
    //     if (token) {
    //         return true;
    //     } else {
    //         token = route.queryParams['token'];
    //         if (token) {
    //             this.authService.setToken(token);
    //             return true;
    //         } else {
    //             this.router.navigate(['/account/login']);
    //             return false;
    //         }
    //     }
    // }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = this.authService.getToken();
    
        if (token) {
            // User is authenticated
            return true;
        } else {
            // User is not authenticated
            // Check if there's a token in the query parameters
            const queryParams = route.queryParams;
            const tokenFromUrl = queryParams['token'];
    
            if (tokenFromUrl) {
                // Store the token temporarily
                this.authService.storeTempToken(tokenFromUrl);
            }
    
            // Redirect to login with returnUrl
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
    
}
