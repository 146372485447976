import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let token = this.authService.getToken();
        if (token && !this.authService.isTokenExpired(token)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        } else if (token && this.authService.isTokenExpired(token)) {
            this.authService.logout();
        }

        return next.handle(request).pipe(
            catchError(error => {
                if (error.status === 401) {
                    this.authService.logout();
                }
                return throwError(error);
            })
        );
    }
}
